export default class InfoMir {

    constructor() {
    }

    static getNorthPoint(coordinate, km) {
      const earth = 6378.137, //radius of the earth in kilometer
      meter = (1 / ((2 * Math.PI / 360) * earth)) / 1000; //1 meter in degree
      const latitude = coordinate.lat + (km * meter);
      const longitude = coordinate.lng // - (km * meter);
      return {latitude, longitude};
    }
}

