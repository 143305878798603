import React, {Component} from 'react';
import '../App.css';
import warning from '../assets/warning.png'
import { Trans } from "react-i18next";

export default class Authentification extends Component{
  constructor(props){
    super(props);

    this.state = {
      code: '',
      errorCode: false
    }

  }

  componentDidMount(){
  }

  _codeIsCorrect(){
    this.props.codeIsCorrect(this.state.code)
  }

  addCode(e){
    console.log('CODE AUTHENTIFICATION ', e.target.value);
    this.setState({
      code: e.target.value
    })
  }

  _showError(){
    if(this.props.errorCode){
      this.setState({
        errorCode: true
      })
      console.log('error state', this.state.errorCode)
    }else{
      console.log('no props error')
    }
  }




  render(){
    const { errorCode } = this.props;
    console.log('props code', this.props.codeName)

    return (
      <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div className="centerOnMiddle">
            {
              errorCode &&
              <div className="errorCtn">
                <img src={warning} alt="Code erroné" />
                <p className="p-error"><Trans i18nKey="errorCodeText1"></Trans> {this.props.codeName} <Trans i18nKey="errorCodeText2"></Trans>.<br></br><Trans i18nKey="errorCodeText3"></Trans> <span style={{textDecoration: 'underline'}}><a href="https://lpsfr.com" target="_blank">www.lpsfr.com</a></span> <Trans i18nKey="errorCodeText4"></Trans></p>
              </div>
            }
            <p><Trans i18nKey="enterCode"></Trans></p>
            <input type="text" placeholder={this.props.t("exampleCode")} onChange={(e) => {this.setState({code: e.target.value})}}/>
            <button onClick={() => this._codeIsCorrect(this.state.code)}><Trans i18nKey="buttonCode"></Trans></button>
        </div>

      </div>
    );
  }
}

