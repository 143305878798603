import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          titleOnBoarding: "Be aware ! Lightning storm is in approach",
          contentOnBoarding: "Secure yourself against lightning strikes thanks to our application Alert@ir TS",
          buttonOnBoarding: "Start",

          loadingContent: "Loading..." ,

          enterCode: "Enter a code",
          buttonCode: "Start",
          errorCodeText1: "The code",
          errorCodeText2: "is wrong",
          errorCodeText3: "Log on",
          errorCodeText4: "for more informations.",

          titleDectionAreaKM: "Detection area (km)",
          changeLanguage: "Change language",
          customMap: "Customization map",
          disconnectedAccount: "Sign out",

          titleContainerStats: "Detection information",
          titleSerialNumber: "Serial number : ",
          titleStateProduct: "State of product : ",
          titleStats: "Detection statistics : ",
          noStats: "No statistics currently available.",
          timeStats: "Detection is launched from : ",
          connected: "Connected",
          disconnected: "Disconnected",

          alertWord: "Alert ! ",
          alertDistance10: "DANGER! Electric storm nearby",
          alertDistance30: "WARNING! Close electrical storm, be vigilant",
          alertDistance50: "VIGILANCE! An electrical storm has been detected in your area",
          alertPerimeter: "DANGER! Electric storm in the perimeter",
          alertModalPerimeter: "Alert activated for",

          noDetection: '0 detection',
          haveDetection: ' detection(s)',

          weatherTitle: "Weather in real time",
          temperature: 'Temperature',
          clouds: 'Clouds',
          humidity: 'Humidity',
          pressure: 'Pressure',
          wind: 'Wind',

          exampleCode: 'Example LPSFR1DAY'
          
        }
      },
      fr: {
        translations: {
          titleOnBoarding: "Soyez averti ! La foudre est en approche",
          contentOnBoarding: "Notre système Alert@ir TS vous prévient lorsque vous êtes dans une zone d'activités orageuses dangereuses.",
          buttonOnBoarding: "Commencer",

          loadingContent: "Chargement...",

          enterCode: "Entrer un code",
          buttonCode: "Commencer",
          errorCodeText1: "Le code",
          errorCodeText2: "est mauvais",
          errorCodeText3: "Connectez-vous sur",
          errorCodeText4: "pour plus d'informations.",

          titleDectionAreaKM: "Périmètre de détection (km)",
          changeLanguage: "Change langage",
          customMap: "Customisation map",
          disconnectedAccount: "Se déconnecter",

          titleContainerStats: "Information de détection",
          titleSerialNumber: "Numéro de série : ",
          titleStateProduct: "État du produit : ",
          titleStats: "Statistiques des détections : ",
          noStats: "Aucune statistique disponible actuellement.",
          timeStats: "La détection est lancée depuis : ",
          connected: 'Connecté',
          disconnected: 'Se déconnecter',

          alertWord: "Alerte ! ",
          alertDistance10: "DANGER! Orage électrique à proximité",
          alertDistance30: "ATTENTION! Orage électrique proche, soyez vigilant",
          alertDistance500: "VIGILANCE! Un orage électrique a été détecté dans votre zone",
          alertPerimeter: "DANGER! Orage électrique dans le périmètre",
          alertModalPerimeter: "Alerte activée sur",

          noDetection: '0 détection',
          haveDetection: ' détection(s)',

          weatherTitle: "Météo en temps réel",
          temperature: 'Température',
          clouds: 'Nuages',
          humidity: 'Humidité',
          pressure: 'Pression',
          wind: 'Vent',

          exampleCode: 'Exemple LPSFR1DAY'

        }
      }
    },
    fallbackLng: "fr",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
