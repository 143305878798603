import React, {Component} from 'react';
import './App.css';
import Map from './map/Map';
import Authentification from './authentification/Authentification';
import LoaderContainer from './loader/LoaderContainer';
import { Api } from 'react-native-lpsfr/dist/lib/webservice'
import uuid from 'uuid';
import Logo from './assets/logo_blue_white.png'
import OnBoarding from './onboarding/OnBoarding';
import BadgeAndroid from './assets/badge_android.png';
import { useTranslation, Trans } from "react-i18next";

const KEY = "device";

export default class AppContainer extends Component{

  constructor(props){
    super(props);
    this.state = {
      isAuthenticated: false,
      showLoader: false,
      showAuth: false,
      showOnBoarding: true,
      width: undefined,
    }
    this._api = new Api("https://api.contact-platform.com");
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async asyncLocalDevice () {
    const rawJson = localStorage.getItem(KEY);
    var device = null;
    if(rawJson) {
      device = JSON.parse(rawJson);
      if(!device.hash || !device.recovery) device = null;
      console.log('Parse ', {device});
    }
    if (!device) {
      device = { hash: uuid.v4(), recovery: uuid.v4() };
      const deviceToString = JSON.stringify(device);
      localStorage.setItem(KEY, deviceToString);
      console.log('in localstorage', {device})
    }
    return device;
  }

  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    
    const getOnBoardingKey = localStorage.getItem('onboarding');
    if(getOnBoardingKey){
      this.setState({
        showOnBoarding: false,
        showAuth: true
      })
    }
    const codeStorage = localStorage.getItem('code');
    if(codeStorage){
      this._device(codeStorage);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth});
    console.log('THIS STATE', this.state.width)
  }

  async _codeIsCorrect(code){ 
    console.log('code from app container ?', code);
    localStorage.setItem('code', code);

    try {
      if(!code) throw "invalid code";
      const device = await this.asyncLocalDevice();
      const registered = await this._api.light().register(device, "");
      const addCode = await this._api.light().add(device, code);
      console.log("registered result", {registered, addCode});
    
      await this._device();
    } catch (e) {
      localStorage.removeItem('code');
      this.setState({errorCode: true});
      console.error(e);
    }
  }

  async _device(){
    const code = localStorage.getItem('code');
    try {
      if(!code) throw 'invalid code';

      // const device = await this.asyncLocalDevice();
      const device = await this._api.light().device(code);
      console.log("registered result light api : ", {device});
      localStorage.setItem('resultCode', JSON.stringify(device))
      
      if(device.serial){
        this.setState({
          showAuth: false,
          showLoader: true,
          device,
          code: code
        })
        setTimeout(() => {
          this.setState({
            isAuthenticated: true
          })
        }, 2000)  
      }
    } catch (e) {
      localStorage.removeItem('code');
      this.setState({errorCode: true, code: code});
      console.log('MAUVAIS CODE')
      console.error(e);
    }
  }

  _hideOnBoarding(){
    localStorage.setItem('onboarding', 'true');

    this.setState({
      showOnBoarding: false,
      showLoader: true,
    })

    setTimeout(() => {
      this.setState({
        showLoader: false,
        showAuth: true
      })
    }, 650)
  }

  _logOutFromMap(){
    localStorage.removeItem('code');
    this.setState({
      isAuthenticated: false,
      showAuth: true
    })
  }

  render(){
    const { t, i18n } = this.props;
    const { isAuthenticated, showLoader, showAuth, width, showOnBoarding} = this.state;
    
    return (
      <div style={{ height: '100vh', width: '100%' }}>

        {
          width && width > 900 ? (
            <div>
              {
                isAuthenticated ? (
                  <Map codeResult={this.state.device} code={this.state.code} logOutFromMap={() => this._logOutFromMap()} t={t} i18n={i18n}/>
                ) : (
                  <div>
                    { showOnBoarding && <OnBoarding hideOnBoarding={() => this._hideOnBoarding()} t={t}/> }
                    { showAuth && <Authentification codeIsCorrect={(code) => this._codeIsCorrect(code)} codeName={this.state.code} errorCode={this.state.errorCode} t={t} /> }
                    { showLoader && <LoaderContainer t={t}/> }
                    <img src={Logo} alt="Logo LPS France" className="logoCenter"/>
                  </div>
                )
              }
            </div>
          ) : (
            <div className="ctnOnBoarding">
              <div className="centerMiddle">
                <h1>Une application mobile existe</h1>
                <p>Lorem ipsum dolor sit amet</p>
              </div>
              <img src={Logo} alt="Logo LPS France" className="logoCenter"/>
            </div>
          )
        }
       
        <p className="copyright">&copy; Lightning Protection Systems France<br></br><a href="https://lpsfr.com/" target="_blank" style={{color: 'white', textDecoration: 'none'}}>www.lpsfr.com</a></p>
      </div>
    );
  }
}

