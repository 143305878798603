import React, {Component} from 'react';
import '../App.css';
import onboarding1 from '../assets/slide1.png';
import { Trans } from "react-i18next";

export default class OnBoarding extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  componentDidMount(){
  }

  render(){
    const { t } = this.props;

    return (
      <div className="ctnOnBoarding">
          <div className="centerMiddle">
            <img src={onboarding1} className="imgOnBoarding" alt="onboarding"/>
            <h1><Trans i18nKey="titleOnBoarding"></Trans></h1>
            <p><Trans i18nKey="contentOnBoarding"></Trans></p>
            <button className="buttonCenterMiddle" onClick={() => this.props.hideOnBoarding()}>
              <Trans i18nKey="buttonOnBoarding"></Trans>
            </button>
          </div>
      </div>
    );
  }
}

