import React, {Component} from 'react';
import '../App.css';
import io from 'socket.io-client';
import GoogleMapReact from 'google-map-react';
import InfoMir from '../utils/infoMir';
import { MapStyle } from './style/MapStyle';
import Distance from '../utils/Distance';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import Chartkick, { LineChart, ColumnChart, PieChart } from 'react-chartkick'
import 'chart.js'
import moment from 'moment';
import LogoBlue from '../assets/logo_blue_white.png';
import Plus from '../assets/plus.png';
import markerts from '../assets/alertairts-icon.png';
import Localization from '../assets/localization.png'
import ModeMap from '../assets/mode.png';
import MyPositionImage from '../assets/myposition.png';
import KM10 from '../assets/10km.png';
import KM30 from '../assets/30km.png';
import KM50 from '../assets/50km.png';
import KMCUSTOM from '../assets/customkm.png';
import Cancel from '../assets/cancel_black.png';
import CancelWhite from '../assets/cancel.png';
import Sound from '../assets/clearly2.mp3';
import Logo from '../assets/logo-lpsfr-white.png';
import gear from '../assets/gear.png';
import closeWhite from '../assets/cancel.png';
import flagfr from '../assets/flag/fr.png';
import flagen from '../assets/flag/en.png';
import contact from '../assets/envelope.png';
import darkMap from '../assets/darkmap.png';
import satelliteMap from '../assets/satellite.png';
import rightSend from '../assets/right-arrow.png';
import WeatherView from '../weather/WeatherView';
import { useTranslation, Trans } from "react-i18next";
import i18next from 'i18next';

Chartkick.options = {
  colors: ["#b00", "#666"]
}

const MarkerTS = () => <div><img src={markerts} alt="Logo" className="markerTS"/></div>;
const MyPosition = () => <img src={MyPositionImage} alt="Logo" className="markerTS"/>

function getWeather(latitude: number, longitude: number){
  return fetch('https://api.contact-platform.com/v2/weather.json?latitude='+latitude+'&longitude='+longitude)
    .then((response) => response.json())
    .then((responseJson:WeatherData) => {
      console.log('REPONSE ????????????', responseJson)
      return responseJson;
    })
    .catch((error) => {
      console.error(error);
    });
}

export interface PositionWeather {
  latitude: number,
  longitude: number
}
export interface Coord {
  lon: number,
  lat: number
}
export interface WeatherCondition {
  id: number,
  main: string,
  description: string,
  icon: string
}
export interface MainInformation {
  temp: number,
  pressure: number,
  humidity: number,
  temp_min: number,
  temp_max: number
}

export interface Wind {
  speed: number,
  deg: number
}

export interface Clouds {
  all: number
}

export interface Sys {
  type: number,
  id: number,
  message: number,
  country: number,
  sunrise: number,
  sunset: number
}

export interface WeatherData {
  coord: Coord,
  weather: WeatherCondition[],
  base: string,
  main: MainInformation,
  wind: Wind,
  clouds: Clouds,
  dt: number,
  sys: Sys,
  timezone: number,
  id: number,
  name: string,
  cod: number
}


export class Position {
  animatedRegion: any
  latitude: any;
  longitude: any;
  constructor(latitude:any, longitude:any) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export interface Point {
  date: moment,
  position: Position
}

export interface Holder {
  minus10Km: Point[],
  minus30Km: Point[],
  minus50Km: Point[]
}

function filterMoment(point: Point) {
  return moment().diff(point.date, "seconds") < 2000;
}

function filter(holder: Holder) {
  holder.minus10Km = holder.minus10Km.filter(p => filterMoment(p));
  holder.minus30Km = holder.minus30Km.filter(p => filterMoment(p));
  holder.minus50Km = holder.minus50Km.filter(p => filterMoment(p));
}


export default class Map extends Component{

  map = undefined;
  maps = undefined;
  holder: Holder = {
    minus10Km: [],
    minus30Km: [],
    minus50Km: [],
    customPerimeter: [],
  };

  refreshInterval: any|undefined = null;

  constructor(props){
    super(props);

    this.state = {
      menuOpen: false,
      modeMap: 'satellite',
      showStats: false,
      markerTSProduct: {
        lat: undefined,
        lng: undefined
      },
      currentPosition: {
        lat: 0,
        lng: 0
      },
      modalStormNear: undefined,
      play:false,
      time: undefined,
      hasStatistics: false,
      connected: false,
      deviceResult: undefined,
      showSettings: false,
      traductionLangue: undefined,
      showModalPerimetre: false,
      weather: undefined,
      showWeatherData: false,
      circleToFar: false
    }

    this.socket = io('https://api.contact-platform.com/');
    this.socket.on('connect', () => {
      if(this.props.codeResult){
        this.socket.emit('ask_device', { serial: this.props.codeResult.serial, code: this.props.code});
      }
      this.setState({connected: true})
    });
    this.socket.on('disconnect', () => {
      this.setState({connected: false})
    });
    this.socket.on('lightning', (message) => {
    const distance = Distance.distance(this.state.markerTSProduct.lat, this.state.markerTSProduct.lng, message.location.latitude, message.location.longitude)
    console.log('Detect a lightning strike...', distance.toFixed(2));

    if(this.state.finalPerimetre){
      console.log('Custom perimeter enable...');
      if(distance <= this.state.finalPerimetre){
        this.holder.customPerimeter.push({date: moment(), position: message.location});
        this.addLightningDot(message);
        this.setState({hasStatistics: true});
        this._playSound();
        store.addNotification({
          title: "Alerte! " +  distance.toFixed(0) + "km",
          message: "DANGER! Orage électrique dans le périmètre",
          type: "danger",
          insert: "top",
          width: 300,
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 30000,
            onScreen: true,
            pauseOnHover: true
          }
        });
      }else if(distance <= 50){
        this.addLightningDot(message);
      }
    }else{
      if(distance <= 10) {
        this.holder.minus10Km.push({date: moment(), position: message.location});
        this._playSound();
        this.addLightningDot(message);
        this.setState({hasStatistics: true})
        store.addNotification({
          title: this.props.t("alertWord") +  distance.toFixed(0) + "km",
          message: this.props.t("alertDistance10"),
          type: "danger",
          insert: "top",
          width: 300,
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 30000,
            onScreen: true,
            pauseOnHover: true
          }
        });
      } else if(distance >= 10 && distance <= 30) {
        this.holder.minus30Km.push({date: moment(), position: message.location});
        this._playSound();
        this.addLightningDot(message);
        this.setState({hasStatistics: true})
        store.addNotification({
          title: this.props.t("alertWord") +  distance.toFixed(0) + "km",
          message: this.props.t("alertDistance30"),
          type: "warning",
          insert: "top",
          width: 300,
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 30000,
            onScreen: true,
            pauseOnHover: true
          }
        });
      } else if(distance >= 30 && distance <= 50) {
        this.holder.minus50Km.push({date: moment(), position: message.location});
        this._playSound();
        this.addLightningDot(message);
        this.setState({hasStatistics: true});
        store.addNotification({
          title: this.props.t("alertWord") +  distance.toFixed(0) + "km",
          message: this.props.t("alertDistance50"),
          type: "warning",
          insert: "top",
          width: 300,
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 30000,
            onScreen: true,
            pauseOnHover: true
          }
        });
      }
    }

    });

    this.socket.on('ask_device_ok', (message) => {
      console.log('SOCKET : Ask device ok : ', message)
    });

    this.socket.on('ask_device_error', (message) => {
      console.log('SOCKET : Ask device error : ', message)
    });

    this.audio = new Audio(Sound);
    
  }

  _playSound(){
    try {
      this.setState({ play: !this.state.play }, () => {this.audio.play()});
    } catch {
      this.setState({ play: !this.state.play }, () => {this.audio.play()});
    }
  }

  onMap(map, maps) {
    this.map = map;
    this.maps = maps;
    this.drawnCircleMarker();
    this._drawCustomCircle();
  }
  

  drawnCircleMarker(){
    const location = {
      lat: this.props.codeResult.latitude,
      lng: this.props.codeResult.longitude
    }

    // Radius of AlertairTS Marker
    new this.maps.Circle({
      strokeColor: 'transparent',
      strokeOpacity: 1,
      strokeWeight: 0,
      fillColor: 'rgba(18,48,138,.6)',
      fillOpacity: 1,
      map: this.map,
      center: location,
      radius: 50000
    });

    new this.maps.Circle({
      strokeColor: '#eb0000 ',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0,
      map: this.map,
      center: location,
      radius: 10000
    });

    new this.maps.Circle({
      strokeColor: '#f35b0d',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0,
      map: this.map,
      center: location,
      radius: 30000
    });

    new this.maps.Circle({
      strokeColor: '#EAD226',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0,
      map: this.map,
      center: location,
      radius: 50000
    });

    this.showInfoCircle();
    // console.log('draw circles...')
  }

  componentDidMount(){
    const { codeResult, code } = this.props;
    if(codeResult){
      const location = {
        lat: codeResult.latitude,
        lng: codeResult.longitude
      }
      this.setState({
        deviceResult: codeResult,
        markerTSProduct: location
      });
    }

    this.refreshInterval = setInterval(() => {
      filter(this.holder);
      this.setState({date: new Date()});
    }, 2000);

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          currentPosition: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        });
        console.log('CURRENT POSITION : ', this.state.currentPosition)
      });
    }

    this._startTimer();
    this.audio.addEventListener('ended', () => this.setState({ play: false }));

    const getLangueFromStorage = localStorage.getItem('i18nextLng');
    if(getLangueFromStorage){
      this.setState({traductionLangue: getLangueFromStorage})
    }
    const getModeMapFromStorage = localStorage.getItem('modeMap');
    if(getModeMapFromStorage){
      this.setState({
        modeMap: getModeMapFromStorage
      })
    }
    const getPerimeterFromStorage = localStorage.getItem('perimeter');
    if(getPerimeterFromStorage){
      console.log('get perimeter from storage...', getPerimeterFromStorage);
      this.setState({
        finalPerimetre: getPerimeterFromStorage,
        showModalPerimetre: true
      });
    }else{
      this.setState({
        finalPerimetre: undefined,
        valuePerimetre: undefined,
        showModalPerimetre: false
      })
    }

    getWeather(codeResult.latitude, codeResult.longitude).then((response) => this.setState({weather: response})).catch(err => console.warn(err));

  }

  componentWillUnmount() {
    if( this.refreshInterval) {
      clearInterval( this.refreshInterval);
      this.refreshInterval = null;
    }
    this.socket.disconnect();
  }

  addLightningDot(lightning) {
    if(!this.map) return;
    if(!lightning || !lightning.location) return;
    if(!lightning.location.latitude || !lightning.location.longitude) return;
    new this.maps.Marker({
      map: this.map,
      position: {lat: lightning.location.latitude, lng: lightning.location.longitude},
      icon: {
        path: this.maps.SymbolPath.CIRCLE,
        fillColor: '#f00',
        fillOpacity: 0.6,
        strokeColor: '#f00',
        strokeOpacity: 0.9,
        strokeWeight: 0.5,
        scale: 4
      }
    });
  }

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    }
  };

  getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: false,
      styles: MapStyle,
      disableDoubleClickZoom: false,
      mapTypeControl: false,
      mapTypeId: this.state.modeMap,
      zoomControl: false,
      clickableIcons: true
    };
  }

  showInfoCircle(){
    const location = {
      lat: this.props.codeResult.latitude,
      lng: this.props.codeResult.longitude
    }

    const info10 = InfoMir.getNorthPoint(location, 9000);
    const info30 = InfoMir.getNorthPoint(location, 29000);
    const info50 = InfoMir.getNorthPoint(location, 49000);

    if(!this.circle1)
    this.circle1 = new this.maps.Marker({
      map: this.map,
      position: {lat: info10.latitude, lng: info10.longitude },
      icon: {
        url: KM10,
        scaledSize: new this.maps.Size(54, 22)
      },
    })
    
    if(!this.circle2)
    this.circle2 = new this.maps.Marker({
      map: this.map,
      position: {lat: info30.latitude, lng: info30.longitude },
      icon: {
        url: KM30,
        scaledSize: new this.maps.Size(54, 22)
      },
    })
    
    if(!this.circle3)
    this.circle3 = new this.maps.Marker({
      map: this.map,
      position: {lat: info50.latitude, lng: info50.longitude },
      icon: {
        url: KM50,
        scaledSize: new this.maps.Size(54, 22)
      },
    })
  }

  _changeModeMap(mode){
    if(mode === 'satellite'){
      this.map.setMapTypeId('satellite');
      this.setState({
        modeMap: 'satellite',
        showSettings: false
      });
    }else{
      this.map.setMapTypeId('terrain');
      this.setState({
        modeMap: 'terrain',
        showSettings: false
      });
    }

    localStorage.setItem('modeMap', mode);
  }

  _centerMap(){
    const { markerTSProduct } = this.state;
    this.map.setCenter(new this.maps.LatLng(markerTSProduct.lat, markerTSProduct.lng));
  }

  _returnLenghtAlert(){
    const { minus10Km, minus50Km, minus30Km, customPerimeter } = this.holder;
    const result = minus10Km.length + minus30Km.length + minus50Km.length + customPerimeter.length;

    if(result === 0) return <Trans i18nKey="noDetection"></Trans>;
    return <span>{result} <Trans i18nKey="haveDetection"></Trans></span>;
  }

  _showStatsCtn(){
    this.setState({
      showStats: true
    })
  }

  _startTimer(){
      var startTimestamp = moment().startOf("day");
      setInterval(() => {
          startTimestamp.add(1, 'second');
          const startTime = startTimestamp.format('HH:mm:ss');
          this.setState({
            time: startTime
          })
      }, 1000);
  }

  _showSettings(){
    this.setState({
      showSettings: true
    })
  }

  _closeModalSettings(){
    if(this.state.valuePerimetre !== undefined){
      this._changePerimetreMap();
    }else{
      this._drawCustomCircle();
    }
    this.setState({
      showSettings: false
    });

  }

  _changeTraduction(traductionLangue){
    const { i18n } = this.props;
    localStorage.setItem('i18nextLng', traductionLangue);
    i18n.changeLanguage(traductionLangue);
    this.setState({
      traductionLangue: traductionLangue,
      showSettings: false
    });
  }

  _handleChangePerimetre(event){
    this.setState({
      valuePerimetre: event.target.value
    });
    console.log('Stat perimetre value from input text', this.state.valuePerimetre);
  }

  _changePerimetreMap(){
    if(this.state.valuePerimetre <= 50){
      this.setState({
        finalPerimetre: this.state.valuePerimetre,
        showModalPerimetre: true,
        valuePerimetre: undefined,
        circleToFar: false
      }, () => {
        this._drawCustomCircle();
        this.setState({
          showSettings: false
        })
      });
    }else{
      //TODO show error in front
      this.setState({circleToFar: true})
      console.log('Custom perimetre too far');
    }
  }

  _resetPerimetreMap(){
    this.setState({
      valuePerimetre: undefined,
      finalPerimetre: undefined,
      showModalPerimetre: false
    }, () => {
      this._drawCustomCircle();
      this.setState({
        showSettings: false
      });
      localStorage.removeItem('perimeter');
    });
    this.inputTitle.value = "";
  }

  _drawCustomCircle(){
    if(this.state.finalPerimetre){
      const location = {
        lat: this.props.codeResult.latitude,
        lng: this.props.codeResult.longitude
      }
      const infoMarker = InfoMir.getNorthPoint(location, this.state.finalPerimetre * 1000);

      if(!this.custom_circle) {
        this.custom_circle = new this.maps.Circle({
          strokeColor: 'transparent',
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: '#EAD226',
          fillOpacity: .6,
          map: this.map,
          center: location,
          radius: this.state.finalPerimetre * 1000
        });
      } else {
        this.custom_circle.setMap(this.map);
        this.custom_circle.setRadius(this.state.finalPerimetre * 1000);
      }
      
      if(!this.custom_marker)
        this.custom_marker = new this.maps.Marker({
          map: this.map,
          position: {lat: infoMarker.latitude, lng: infoMarker.longitude },
          icon: {
            url: KMCUSTOM,
            scaledSize: new this.maps.Size(54, 22)
          },
        });
      else {
        this.custom_marker.setPosition({lat: infoMarker.latitude, lng: infoMarker.longitude });
        this.custom_marker.setMap(this.map);
      }

      this.setState({
        newDate: new Date()
      })
      console.log('passing ---------------');
      localStorage.setItem('perimeter', this.state.finalPerimetre);
    } else {
      if(this.custom_circle) this.custom_circle.setMap(null);
      if(this.custom_marker) this.custom_marker.setMap(null);
    } 
  }

  _showWeather(){
    this.setState({showWeatherData: true})
  } 
  _renderWeather(){
    const map: any[] = [];
    map["01d"] = require('../assets/weather/sun.png');
    map["01n"] = require('../assets/weather/moon.png');
    map["02d"] = require('../assets/weather/sun-cloud.png');
    map["02n"] = require('../assets/weather/moon-cloud.png');
    map["03d"] = require('../assets/weather/cloud.png');
    map["03n"] = require('../assets/weather/cloud.png');
    map["04d"] = require('../assets/weather/cloud.png');
    map["04n"] = require('../assets/weather/cloud.png');
    map["09d"] = require('../assets/weather/cloud-rain.png');
    map["09n"] = require('../assets/weather/cloud-rain.png');
    map["10d"] = require('../assets/weather/sun-cloud-rain.png');
    map["10n"] = require('../assets/weather/sun-cloud-rain.png');
    map["11d"] = require('../assets/weather/cloud-lightning.png');
    map["11n"] = require('../assets/weather/cloud-lightning.png');
    map["13d"] = require('../assets/weather/snow.png');
    map["13n"] = require('../assets/weather/snow.png');
    map["50d"] = require('../assets/weather/wind.png');
    map["50n"] = require('../assets/weather/wind.png');

    return (
      <button className="ctnWeather" onClick={() => this.setState({showWeatherData: true})}>
        <div className="ctnImgWeather">
          {
            this.state.weather.weather.filter(item => item.icon && map[item.icon]).map((item, index) => <img key={index} src={map[item.icon]} className="imgWeather" alt="icon weather"/>)
          }
        </div>
        <p className="textWeather">{this.state.weather.main.temp.toFixed(0)}°C</p>
      </button>
    )
  }

  render(){
    const { minus10Km, minus50Km, minus30Km, customPerimeter } = this.holder;
    const { currentPosition, showSettings, weather, circleToFar, showWeatherData, menuOpen, showStats, markerTSProduct, hasStatistics, connected } = this.state;

    console.log('Selected custom perimeter...', this.state.finalPerimetre);
    console.log('Active custom perimeter ?', this.state.valuePerimetre);
    return (

      <div style={{ height: '100vh', width: '100%' }}>

        <ReactNotification />
      
        {
          menuOpen && 
          <div className="fullBlack"></div>
        }

        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDE_iRqxlU4XL42RXCY3Oi1ncSTwqBLFks'}}
          defaultCenter={markerTSProduct ? markerTSProduct : this.props.center}
          center={markerTSProduct ? markerTSProduct : this.props.center}
          defaultZoom={9}
          resetBoundsOnResize
          options={this.getMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => this.onMap(map, maps) }
        >

          <MarkerTS 
            lat={this.props.codeResult.latitude}
            lng={this.props.codeResult.longitude}
            text="Alert@ir TS"
          />

          {/* <MyPosition
            lat={currentPosition.lat}
            lng={currentPosition.lng}
          /> */}
          
        </GoogleMapReact>

          {
            this.state.finalPerimetre && this.state.showModalPerimetre &&
            <span className="modeAlerte">
              <p><span className="connectedGreen"></span><Trans i18nKey="alertModalPerimeter"></Trans> {this.state.finalPerimetre} km</p>
            </span>
          }

          <div className="ctnCompteur">
            <div onClick={() => this._showStatsCtn()}>
              <img src={Plus} className="imgPlusDetection" alt="#" />
            </div>
            <p>{this._returnLenghtAlert()}</p>
          </div>

        <div className="connectedCtn">
          <p>
            <span className="connectedGreen" style={connected ? ({background: 'green'}) : ({background: 'red'})}></span>
            { connected ? (<Trans i18nKey="connected"></Trans>) : (<Trans i18nKey="disconnected"></Trans>) }
          </p>
        </div>


        <div className="menuCtn">
          <ul>
            <li onClick={() => this._showWeather()}>
            { weather && this._renderWeather()}
            </li>
            <li onClick={() => this._showSettings()}>
              <img src={gear} alt="Afficher les configurations" />
            </li>
            <li onClick={() => this._centerMap()}>
              <img src={Localization} alt="Recentrer sur l'utilisateur" />
            </li>
          </ul>
        </div>

        {
          showWeatherData && 
          <div className="ctnSettingShow">
            <div className="centerMiddleSetting">
              <p className="titleModalSetting"><Trans i18nKey="weatherTitle"></Trans></p>
              <img src={closeWhite} alt="close configuration" className="close" onClick={() => this.setState({showWeatherData: false})}/>
              <ul>
                <li className="flexLi" style={{marginBottom: 24}}>
                    <WeatherView weatherData={this.state.weather} t={this.props.t}/>
                </li>
              </ul>
            </div>
          </div>
        }

        <div className={showSettings ? 'ctnSettingShow' : 'ctnSetting'}>
          <div className="centerMiddleSetting">
            <p className="titleModalSetting">Configuration</p>
            <img src={closeWhite} alt="close configuration" className="close" onClick={() => this._closeModalSettings()}/>
            <ul>
              <li className="flexLi" style={{marginBottom: 24}}>
                <p><Trans i18nKey="titleDectionAreaKM"></Trans></p>
                <span style={{alignItems: 'flex-end'}}>
                  <input ref={el => this.inputTitle = el} type="number" min="0" max="50" placeholder={this.state.finalPerimetre ? this.state.finalPerimetre : undefined} onChange={(event) => this._handleChangePerimetre(event)}/>
                  {
                    this.state.finalPerimetre ? (
                      <button className="buttonChangeDetection" onClick={() => this._resetPerimetreMap()}>
                        <img src={CancelWhite} alt="changer la detection" className="imgSendNumber"/>
                      </button>
                    ) : (
                      <button className="buttonChangeDetection" onClick={() => this._changePerimetreMap()}>
                      <img src={rightSend} alt="changer la detection" className="imgSendNumber"/>
                    </button>
                    )
                  }

                </span>
              </li>

              { circleToFar && 
                <li className="flexLi" style={{marginBottom: 24}}>
                  <p>Perimeter to far > 50 km</p>
                </li>
              }
              
              <li className="flexLi">
                <p><Trans i18nKey="changeLanguage"></Trans></p>
                <span>
                  <div className={this.state.traductionLangue === 'fr' || this.state.traductionLangue === 'fr-FR' ? 'divFlagBorder' : 'divFlag'} onClick={() => this._changeTraduction('fr')}>
                    <img src={flagfr} alt="flag fr" className="img-flag" />
                  </div>
                  <div className={this.state.traductionLangue === 'en' || this.state.traductionLangue === 'en-EN' ? 'divFlagBorder' : 'divFlag'} onClick={() => this._changeTraduction('en')}>
                    <img src={flagen} alt="flag en" className="img-flag" />
                  </div>
                </span>
              </li>
              <li className="flexLi">
                <p><Trans i18nKey="customMap"></Trans></p>
                <span>
                    <img src={satelliteMap} alt="satellite mode map" className={this.state.modeMap === 'satellite' ? "divImgModeBorder" : "divImgMode"} onClick={() => this._changeModeMap('satellite')}/>
                    <img src={darkMap} alt="dark mode map" className={this.state.modeMap === 'terrain' ? "divImgModeBorder" : "divImgMode"} onClick={() => this._changeModeMap('terrain')}/>
                </span>
              </li>
            </ul>

            <div className="flexButtonModal">
              <button onClick={() => this.props.logOutFromMap()}>
                <Trans i18nKey="disconnectedAccount"></Trans>
              </button>
              <a href="mailto:infos@lpsfr.com" className="a-button-flexModal">
                <img src={contact} alt="#" className="imgContact"/>
                Support LPS France
              </a>
            </div>
          </div>
        </div>

        <div className={showStats ? 'ctnStatsShow' : 'ctnStats' }>
            <img className="closeShowStats" src={Cancel} alt="#" onClick={() => this.setState({showStats: false})}/>
            <h2 className="titleStats"><Trans i18nKey="titleContainerStats"></Trans></h2>

            <div className="centerStats">

             <h3><Trans i18nKey="titleSerialNumber"></Trans><br></br>
             <span className="statsLight" style={{textTransform: 'uppercase'}}>
                {this.props.codeResult.serial}
             </span>
             </h3>

             <h3 style={{marginTop: 24}}><Trans i18nKey="titleStateProduct"></Trans><br></br>
             <span className="statsLight">
               <span className="connectedGreen" style={connected ? ({background: 'green'}) : ({background: 'red'})}></span>
                {connected ? (<Trans i18nKey="connected"></Trans>) : (<Trans i18nKey="disconnected"></Trans>)}
             </span>
             </h3>

              <h3 style={{marginTop: 24}}><Trans i18nKey="timeStats"></Trans><br></br>
                <span className="statsLight">
                  {this.state.time}
                </span>
              </h3>

              <h3 className="marginTopTitle"><Trans i18nKey="titleStats"></Trans></h3>
              {
                hasStatistics ? (
                  <div>
                    <PieChart 
                      messages={{empty: "No data"}}
                      colors={["rgba(222,44,44, 1)", "#E07F17", "#F4E90F"]}
                      data={[["< 10 km", minus10Km.length], [" < 30 km", minus30Km.length], ["< 50 km", minus50Km.length], ["Custom", customPerimeter.length]]}
                    />
                    <div className="br"></div>
                    <ColumnChart
                      data={[["< 10 km", minus10Km.length], [" < 30 km", minus30Km.length], ["< 50 km", minus50Km.length], ["Custom", customPerimeter.length]]}
                      options={{colors: ["rgba(222,44,44, 1)", "#E07F17", "#F4E90F"]}}
                    />
                  </div>
                ) : (
                  <p style={{fontSize: 14}}>
                    <Trans i18nKey="noStats"></Trans>
                  </p>
                )

              }

            </div> 
        </div>

        <a href="https://lightning-protection-systems.com/fr/" target="_blank">
          <img src={LogoBlue} alt="Logo LPS France" className="logo"/>
        </a>
  
      </div>
    );
  }
}

