import React, {Component} from 'react';
import AppContainer from './AppContainer';
import i18n from "./i18n";
import { useTranslation, Trans } from "react-i18next";

export default function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <AppContainer t={t} i18n={i18n}/>
  );
}

