import React, {Component} from 'react';
import '../App.css';
import { Trans } from "react-i18next";


export default class LoaderContainer extends Component{
  constructor(props){
    super(props);

    this.state = {
    }
  }

  render(){
    return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div className="centerOnMiddle">
        <div className="loader">Loading...</div>
        <p style={{fontSize: 16}}><Trans i18nKey="loadingContent"></Trans></p>
      </div>
    </div>
    );
  }
}

