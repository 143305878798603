import React, {Component} from 'react';
import '../App.css';
import { useTranslation, Trans } from "react-i18next";
import indicator from '../assets/northsouthwesteast.png';
import rose from '../assets/windrose.png';
import circleRed from '../assets/circle_winds.png';

export default class WeatherView extends Component{
  constructor(props){
    super(props);
    this.state = {
      rotatedWind: false
    }
  }

  componentDidMount(){
    const { weatherData } = this.props;
    if(weatherData.wind.deg){
      this.setState({
        rotatedWind: true
      })
    }
    
  }

  render(){
    const { weatherData } = this.props;
    console.log('Weather deg wind', weatherData.wind.deg);
    return (
      <div className="ctnWeatherParagraphe">

        <div className="flexWeather">
          <p className="textWeather"><Trans i18nKey="temperature"></Trans> : {weatherData.main.temp.toFixed(0)}°C</p>
          <p className="textWeather"><Trans i18nKey="clouds"></Trans> : {weatherData.clouds.all}%</p>
          <p className="textWeather"><Trans i18nKey="humidity"></Trans> : {weatherData.main.humidity}%</p>
          <p className="textWeather"><Trans i18nKey="pressure"></Trans> : {weatherData.main.pressure} hPa</p>
          <p className="textWeather"><Trans i18nKey="wind"></Trans> : {weatherData.wind.speed} km/h</p>
        </div>

        <div className="flexImgWeather">
          <img src={indicator} className="imgIndicator" alt="indicator direction wind" />
          <img src={circleRed} className={"imgCircle"} style={{transform: 'rotate('+weatherData.wind.deg.toString()+'deg)'}} alt="circle red for wind direction"/>
          <img src={rose} className="imgCompass" alt="Compass wind"/>
        </div>
      </div>
    );
  }
}

